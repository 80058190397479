import { useState, useLayoutEffect  } from "react" 
import {Row, Col} from 'react-bootstrap'

import TreeView from "../meTree/TreeView"
import {meSplitter} from '../Shared/meGlobal.js'

import {Cookie} from '../Shared/Api.js'

const Home = ({search, connected, Etat}) => {
        //alert(connected)
        const waqafaat = "https://moshaf.akademy.ma/waqafaat/"

        const [selected, Selected] = useState(Cookie('meSafha')??0)

        const [safha, Safha] = useState('')

        const splitter = new meSplitter("Side", "HomeContainer")

        //const [items, Items] = useState(splitter.items.length)

        useLayoutEffect (()=>{  // like useEffect but synchronously after all DOM
                Etat(`splitter ${splitter.items.length}`)
                splitter.selectorAll()

                if(selected>0)
                        onNode(selected)
        },[])

        const onNode = (id) => {
                Selected(id)
                Etat(`Wajh ${id}`)
                let name = (id||0).toString().padStart(3,'0')+'.jpg'
                Safha(waqafaat+name)
        }

        const onSafha = (event) => {
                let cr = event.currentTarget.getBoundingClientRect()
        }


        return (
                connected ?
                <div className="Home" id="HomeContainer"
                onMouseDown={(e)=>{splitter.mouseDown(e)}} 
                onMouseMove={(e)=>{splitter.mouseMove(e)}} 
                onMouseUp={(e)=>{return splitter.mouseUp(e)}}>

                <div className="side" id="SideDetail" style={{width: '67%'}}>
                        <div style={{textAlign:'center', width:'100%', height:'100%'}}>
                        <img className="page-fit-center" alt="القرآن الكريم"  id="image" 
                        src={safha} 
                        onClick={onSafha}
                        /> 
                        </div>
                </div>

                <div className="side" id="SideTree" style={{width: '33%'}}>
                        <TreeView onChange={onNode} selected={selected} />
	        </div>
            </div>
            : <>
                <div className="Title" style={{width:'100%', margin:'auto'}}>
            <h1>moshaf.akademy.ma</h1>
            <h2>قم بتسجيل الدخول للمتابعة</h2>
            <h3>Log In to continue   </h3>
                </div> 
                </>
        
        )
}

export default Home